export const verifyemailLocalization = {
  'VerifyEmail: title': {
    eng: 'Email confirmation',
    ru: 'Подтверждение email',
    pt: 'Confirmação de e-mail',
    cn: 'Email confirmation',
  },
  'VerifyEmail: text': {
    eng:
      'We have sent an email with a confirmation link to <span>$[1]</span>. Follow the link to complete your account registration',
    ru: 'Мы отправили вам письмо на <span>$[1]</span>. Перейдите по ссылке, чтобы завершить регистрацию аккаунта',
    pt:
      'Enviamos um e-mail com um link de confirmação para <span>$[1]</span>. Siga o link para concluir o registro da sua conta',
    cn:
      'We have sent an email with a confirmation link to <span>$[1]</span>. Follow the link to complete your account registration',
  },
  'VerifyEmail: button': {
    eng: 'Resend email',
    ru: 'Отправить письмо еще раз',
    pt: 'Reenviar e-mail',
    cn: 'Resend email',
  },
  'VerifyEmail: resend email text': {
    eng: 'Resend in $[1] seconds',
    ru: 'Отправить снова через $[1] секунд',
    pt: 'Reenviar em $[1] segundos',
    cn: 'Resend in $[1] seconds',
  },
  'VerifyEmail: success': {
    eng: 'Your email has been successfully confirmed.',
    ru: 'Ваш email успешно подтвержден.',
    pt: 'Seu e-mail foi confirmado com sucesso.',
    cn: '您的邮箱地址已通过审核。',
  },
  'VerifyEmail:BE-error accountsadmin.email.verification.expired.token': {
    eng: 'The token has expired',
    ru: 'Токен устарел',
    pt: 'O token expirou',
    cn: 'The token has expired',
  },
  'VerifyEmail:BE-error accountsadmin.email.verification.invalid.token': {
    eng: 'Invalid token',
    ru: 'Неверный токен',
    pt: 'Token inválido',
    cn: 'Invalid token',
  },
  'VerifyEmail: have account': {
    eng: 'Have another account? ',
    ru: 'У вас есть другой аккаунт? ',
    pt: 'Tem outra conta? ',
    cn: 'Have another account? ',
  },
  'VerifyEmail:BE-error accountsadmin.autologin.invalid.token': {
    eng: 'The token has expired',
    ru: 'Токен устарел',
    pt: 'O token expirou',
    cn: 'The token has expired',
  },
  'VerifyEmail:BE-error accountsadmin.autologin.expired.token': {
    eng: 'Your verification link has expired',
    ru: 'Упс, ваша ссылка для подтверждения регистрации устарела',
    pt: 'Seu link de confirmação expirou',
    cn: 'Your verification link has expired',
  },
};
