import React from 'react';
import { NavLink } from 'react-router-dom';
import { history } from 'App';
import queryString from 'query-string';

import { t } from 'localization';
import { sendAnalyticEvents } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import LoginService from 'service/LoginService';
import { ssoService } from 'service/SsoService';
import { BasePage } from 'views/BasePage';

class OauthPage extends BasePage<any, any> {
  static contextType = AppContext;

  LoginService = new LoginService();

  state = {
    loaded: false,
    errors: [],
  };

  componentDidMount() {
    const { appConfig } = this.context;
    const {
      location: { search },
    } = this.props;
    const parsedLocationSearch = queryString.parse(search.replace('?', ''));
    const openIdUserNotFoundError = parsedLocationSearch?.errorCode;
    if (
      !appConfig.authorization.oauth2AuthorizationEnabled &&
      !appConfig.authorization.onpremOpenIDAuthorizationEnabled
    ) {
      history.push('/c/login');
    }
    const load = async () => {
      if (openIdUserNotFoundError) {
        return this.setState({ loaded: true });
      }

      try {
        const { data: currentUser } = await this.LoginService.checkIsUserAuthorized();

        sendAnalyticEvents(
          {
            event: 'GAEvent',
            eventAction: `${parsedLocationSearch.event} success`,
            eventCategory: parsedLocationSearch.event as string,
            eventLabel: parsedLocationSearch.provider as string,
            options: { userId: currentUser.userData.userId },
          },
          {
            event: 'reachGoal',
            eventType: parsedLocationSearch.event as string,
          },
          { eventType: parsedLocationSearch.event as string },
          {
            event: 'track',
            eventType: parsedLocationSearch.event === 'registration' ? 'CompleteRegistration' : 'Login',
          }
        );

        const redirectUrl = localStorage.CLOUD_REDIRECT_URL;

        setTimeout(() => {
          localStorage.removeItem('CLOUD_REDIRECT_URL');
          if (
            appConfig.registration.userNeedToSpecifyCountryIsoCode &&
            !currentUser.userData.countryIsoCode &&
            !currentUser.userData.internal
          ) {
            history.push(`/c/select-country?redirectUrl=${redirectUrl}`);
          } else {
            ssoService.stayOrRedirect(redirectUrl);
          }
        }, 3000);
      } catch (e) {
        console.log(e);
      }
    };

    load();
  }

  renderHead = () => {
    const {
      location: { search },
    } = this.props;
    const parsedLocationSearch = queryString.parse(search.replace('?', ''));
    const openIdUserNotFoundError = parsedLocationSearch?.errorCode;
    const openIdUserNotFoundEmail = parsedLocationSearch?.email as string;
    const isEmailExist =
      openIdUserNotFoundEmail && openIdUserNotFoundEmail !== 'null' && openIdUserNotFoundEmail !== 'undefined';

    if (
      [
        'accountsadmin.authorization.account.blocked',
        'accountsadmin.user.by.email.not.found',
        'accountsadmin.authorization.user.disabled',
      ].includes(openIdUserNotFoundError as string)
    ) {
      return (
        <h3 className='oauth-error'>
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                `OauthPage: oauth error text ${openIdUserNotFoundError}${
                  openIdUserNotFoundError === 'accountsadmin.user.by.email.not.found' && !isEmailExist
                    ? ' no email'
                    : ''
                }`,
                openIdUserNotFoundEmail
              ),
            }}
          />
          <NavLink to='/c/login'>
            {t(
              `OauthPage: oauth error login button ${openIdUserNotFoundError}${
                openIdUserNotFoundError === 'accountsadmin.user.by.email.not.found' && !isEmailExist ? ' no email' : ''
              }`
            )}
          </NavLink>
          {t(
            `OauthPage: oauth error text 2 ${openIdUserNotFoundError}${
              openIdUserNotFoundError === 'accountsadmin.user.by.email.not.found' && !isEmailExist ? ' no email' : ''
            }`
          )}
        </h3>
      );
    }
  };
}

export default OauthPage;
