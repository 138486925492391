import React from 'react';
import { history } from 'App';
import { Button } from '@just-ai/just-ui';

import { t } from 'localization';

import { AppContext } from 'components/AppContext';
import LoginService from 'service/LoginService';
import { BasePage } from 'views/BasePage';

class OnlyJaicp extends BasePage<any, any> {
  static contextType = AppContext;

  LoginService = new LoginService();

  state = {
    fetching: false,
    errors: [],
    loaded: true,
  };

  async componentDidMount() {
    const { setCurrentUser } = this.context;
    this.setState({ fetching: true });

    try {
      const { data } = await this.LoginService.checkIsUserAuthorized();
      await setCurrentUser(data);
      this.setState({
        fetching: false,
      });
    } catch (e) {
      history.push(`/c/login`);
    }
  }

  renderHead = () => {
    const {
      appConfig: { euroInstance },
    } = this.context;

    return (
      <div className='base-page_formarea-head'>
        <h2>{t('OnlyJaicp: form header text', t(euroInstance ? 'OnlyJaicp:ToviePlatform' : 'OnlyJaicp:JAICP'))}</h2>
      </div>
    );
  };

  renderButtons = () => {
    const {
      appConfig: { domains, euroInstance },
    } = this.context;
    const jaicp = domains?.jaicp;
    return (
      jaicp && (
        <div className='base-page_formarea-buttons center'>
          <Button
            color='primary'
            onClick={() => {
              window.location.href = `${window.location.protocol}//${jaicp.domain}`;
            }}
          >
            {t('OnlyJaicp: submit button text', t(euroInstance ? 'OnlyJaicp:ToviePlatform' : 'OnlyJaicp:JAICP'))}
          </Button>
        </div>
      )
    );
  };

  renderCaptcha = () => {};
}

export default OnlyJaicp;
