import { PasswordResetEndpointApi, ResetPasswordDto, SendResetPasswordDto } from '../api/cloud/client';
import { axios } from '../pipes/functions';

export default class PasswordResetService {
  static readonly BASE_PATH = '';
  private PasswordResetApi: PasswordResetEndpointApi;

  constructor() {
    this.PasswordResetApi = new PasswordResetEndpointApi({}, PasswordResetService.BASE_PATH, axios);
  }

  sendResetPasswordEmail = async (sendResetPasswordObject: SendResetPasswordDto, gRecaptchaResponse: any) => {
    try {
      await this.PasswordResetApi.sendResetPasswordEmail(sendResetPasswordObject, {
        headers: { 'g-recaptcha-response': gRecaptchaResponse },
      });
    } catch (error) {
      throw error;
    }
  };

  resetPassword = async (passwordResetObject: ResetPasswordDto) => {
    try {
      await this.PasswordResetApi.resetPassword(passwordResetObject);
    } catch (error) {
      throw error;
    }
  };
}
