import React from 'react';
import './index.scss';
import { logo } from './Logo';
import { DropdownButton, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui/dist';
import { useAppContext } from '../AppContext';
import { OptionsResponseDto } from '../../api/cloud/client';
import { t } from 'localization';
import { DropdownItem } from '@just-ai/just-ui/dist';

import { history } from '../../App';
import { Link } from 'react-router-dom';
import localize from '../../localization';

import { headerLocalization } from './localization/header.loc';
import CloudMenu from '@just-ai/just-ui/dist/CloudMenu';
import { getAllowedRoutes } from 'routes';
import logoUsd from '../../images/tovie/t-cloudFull.svg';

localize.addTranslations(headerLocalization);

const Header = () => {
  // @ts-ignore
  const { appConfig, currentUser }: OptionsResponseDto | null = useAppContext();

  const { domains, euroInstance } = appConfig;

  return (
    <div className='header'>
      <div className='logo'>
        <Link to='/'>{euroInstance ? <img src={logoUsd} alt='cloud logo' /> : logo}</Link>
      </div>
      <div className='header-menu-wrap'>
        <DropdownButton direction='down'>
          <DropdownToggle>
            <div
              className='user-menu-button'
              data-test-id='cloud.header.userButton'
              style={
                currentUser.userData.avatarUrl
                  ? { backgroundImage: `url(${currentUser.userData.avatarUrl})` }
                  : undefined
              }
            >
              {!currentUser.userData.avatarUrl && <Icon name='falUser' color='primary' />}
            </div>
          </DropdownToggle>
          <DropdownMenu>
            {getAllowedRoutes().map(sidebarItem => (
              <DropdownItem key={`dropdown-${sidebarItem.title}`} onClick={() => history.push(sidebarItem.link)}>
                {t(`Header: ${sidebarItem.title}`)}
              </DropdownItem>
            ))}
            <DropdownItem onClick={() => history.push('/c/logout')} data-test-id='cloud.header.user.logout'>
              <span style={{ color: 'var(--gray-600)' }}>{t('Header: logout')}</span>
            </DropdownItem>
          </DropdownMenu>
        </DropdownButton>
        <CloudMenu
          cloudDomains={domains}
          countryIsoCode={currentUser?.userData?.countryIsoCode}
          translate={t}
          universalLoginMenuEnabled
          isEuroInstance={euroInstance}
        />
      </div>
    </div>
  );
};

export default Header;
