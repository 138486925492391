import React from 'react';
import { useAppContext } from '../AppContext';
const ThemeAimyvoice = React.lazy(() => import('./ThemeAimyvoice'));
const ThemeCaila = React.lazy(() => import('./ThemeCaila'));
const ThemeCloud = React.lazy(() => import('./ThemeCloud'));
const ThemeJaicp = React.lazy(() => import('./ThemeJaicp'));
const ThemeAimylogic = React.lazy(() => import('./ThemeAimylogic'));

const Theme = ({ children }: { children: any }) => {
  // @ts-ignore
  const { theme } = useAppContext();

  const ThemeComponent = (() => {
    switch (theme) {
      case 'aimyvoice':
        return ThemeAimyvoice;
      case 'caila':
        return ThemeCaila;
      case 'aimychat':
      case 'jaicp':
        return ThemeJaicp;
      case 'aimylogic':
        return ThemeAimylogic;
      case 'cloud':
        return ThemeCloud;
      case 'copilot':
      default:
        return ThemeJaicp;
    }
  })();

  return (
    <React.Suspense fallback={<></>}>
      <ThemeComponent>{children}</ThemeComponent>
    </React.Suspense>
  );
};

export default Theme;
