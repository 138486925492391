import { mapKeys } from 'lodash';
import { commonLocalization } from './decomposed/common.loc';

import { appStores } from '../store';

const Localize = require('localize');
require('decliner/dist/decliner.min');
const radix = 10;

const localize = new Localize({ ...commonLocalization });

localize.addTranslations = translate => {
  mapKeys(translate, key => {
    // eslint-disable-next-line no-prototype-builtins
    if (!localize.getTranslations().hasOwnProperty(key)) {
      localize.loadTranslations(translate);
    }
  });
};

localize.checkExistKey = key => {
  return key !== localize.translate(key);
};

localize.throwOnMissingTranslation(false);

localize.decliner = (arr, count) => {
  return arr.decline(parseInt(count, radix));
};

export function useLocalization() {
  return appStores.CurrentUser(state => state.lang);
}

export const { translate: t } = localize;
export default localize;
