export const phoneverificationandloginLocalization = {
  'PhoneVerificationAndLogin:BE-error accountsadmin.captcha.validation.failed': {
    eng: 'CAPTCHA validation failed',
    ru: 'Ошибка при проверке капчи',
    pt: 'Falha na validação CAPTCHA',
    cn: 'CAPTCHA validation failed',
  },
  'PhoneVerificationAndLogin: search placeholder': {
    eng: 'Search',
    ru: 'Поиск',
    pt: 'Pesquisa',
    cn: 'Search',
  },
  'PhoneVerificationAndLogin: email verified': {
    eng: 'Your email has been verified. Add your phone number to log into your account quickly',
    ru: 'Ваша почта подтверждена. Укажите номер телефона, чтобы быстро заходить в аккаунт',
    pt: 'Seu e-mail foi confirmado. Adicione seu número de telefone para fazer login em sua conta rapidamente',
    cn: 'Your email has been verified. Add your phone number to log into your account quickly',
  },
  'PhoneVerificationAndLogin: phone input label': {
    eng: 'Phone number',
    ru: 'Номер телефона',
    pt: 'Número de telefone',
    cn: 'Phone number',
  },
  'PhoneVerificationAndLogin: button to next step': {
    eng: 'Continue',
    ru: 'Продолжить',
    pt: 'Continuar',
    cn: 'Continue',
  },
  'PhoneVerificationAndLogin: button skip': {
    eng: 'Skip this step',
    ru: 'Пропустить',
    pt: 'Ignorar este passo',
    cn: 'Skip this step',
  },
  'PhoneVerificationAndLogin: sms helper text': {
    eng: 'We will send you an SMS message with a confirmation code',
    ru: 'Мы пришлем вам SMS-сообщение с кодом подтверждения',
    pt: 'Nós lhe enviaremos uma mensagem SMS com um código de confirmação',
    cn: 'We will send you an SMS message with a confirmation code',
  },
  'PhoneVerificationAndLogin: sms code title': {
    eng: 'Enter the code from the SMS message',
    ru: 'Введите код из SMS',
    pt: 'Digite o código da mensagem SMS',
    cn: 'Enter the code from the SMS message',
  },
  'PhoneVerificationAndLogin: change number': {
    eng: 'Change phone number',
    ru: 'Изменить номер телефона',
    pt: 'Alterar número de telefone',
    cn: 'Change phone number',
  },
  'PhoneVerificationAndLogin: code helper text': {
    eng: 'Send again in $[1] sec.',
    ru: 'Повторная отправка через $[1] сек.',
    pt: 'Enviar novamente em $[1] s',
    cn: 'Send again $[1] sec.',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.smscode.phone.already.used': {
    eng: 'This phone number is already taken',
    ru: 'Номер телефона уже занят другим пользователем',
    pt: 'Este número de telefone já está ocupado',
    cn: 'This phone number is already taken',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.smscode.enter.again': {
    eng: 'Incorrect code. $[1] left',
    ru: 'Неверный код. Осталось $[1]',
    pt: 'Código incorreto. $[1] restantes',
    cn: 'Incorrect code. $[1] left',
  },
  'PhoneVerificationAndLogin: attempts 1': {
    eng: '$[1] attempt',
    ru: '$[1] попытка',
    pt: '$[1] tentativa',
    cn: '$[1] attempt',
  },
  'PhoneVerificationAndLogin: attempts 2': {
    eng: '$[1] attempts',
    ru: '$[1] попытки',
    pt: '$[1] tentativas',
    cn: '$[1] attempts',
  },
  'PhoneVerificationAndLogin: attempts 5': {
    eng: '$[1] attempts',
    ru: '$[1] попыток',
    pt: '$[1] tentativas',
    cn: '$[1] attempts',
  },
  'PhoneVerificationAndLogin: resend sms': {
    eng: 'Send again',
    ru: 'Отправить SMS еще раз',
    pt: 'Enviar novamente',
    cn: 'Send again',
  },
  'PhoneVerificationAndLogin: dont have sms': {
    eng: 'I did not get the message',
    ru: 'Я не получил SMS',
    pt: 'Não recebi a mensagem',
    cn: 'I did not get the message',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.smscode.send.again': {
    eng: 'Incorrect code. Request another',
    ru: 'Неверный код. Запросите повторную отправку кода',
    pt: 'Código incorreto. Solicitar outro',
    cn: 'Incorrect code. Request another',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.smscode.too.many.attempts': {
    eng: 'Too many attempts.',
    ru: 'Превышен лимит повторной отправки.',
    pt: 'Muitas tentativas.',
    cn: 'Too many attempts.',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.validation.constraints.Phone': {
    eng: 'Incorrect phone number format',
    ru: 'Некорректный формат номера телефона',
    pt: 'Formato incorreto de número de telefone',
    cn: 'Incorrect phone number format',
  },
  'PhoneVerificationAndLogin: resend limits exceeded': {
    eng: 'You have exceeded the code request quota. Try again in an hour',
    ru: 'Вы превысили лимит отправки SMS-сообщений. Повторная отправка будет доступна через час',
    pt: 'Você excedeu a cota de solicitação de código. Tente novamente em uma hora',
    cn: 'You have exceeded the code request quota. Try again in an hour',
  },
  'PhoneVerificationAndLogin: button back': {
    eng: 'Back',
    ru: 'Назад',
    pt: 'Voltar',
    cn: 'Back',
  },
  'PhoneVerificationAndLogin: dont receive sms': {
    eng:
      'Check if you have entered the correct phone number and try again. If that does not help, please contact our customer support team at $[1]',
    ru:
      'Проверьте, правильно ли вы указали номер телефона, и попробуйте снова. Если не получилось, напишите нам в техническую поддержку на $[1]',
    pt:
      'Verifique se você digitou o número de telefone correto e tente novamente. Se isso não ajudar, entre em contato com nossa equipe de suporte ao cliente em $[1]',
    cn:
      'Check if you have entered the correct phone number and try again. If that does not help, please contact our customer support team at $[1]',
  },
  'PhoneVerificationAndLogin: login with': {
    eng: 'Sign in with',
    ru: 'Вход с помощью',
    pt: 'Entrar com',
    cn: 'Sign in with',
  },
  'PhoneVerificationAndLogin: login with email': {
    eng: 'Email',
    ru: 'Email',
    pt: 'E-mail',
    cn: 'Email',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.smscode.user.not.found': {
    eng: 'The user with this phone number does not exist',
    ru: 'Пользователя с таким номером телефона не существует',
    pt: 'O usuário com este número de telefone não existe',
    cn: 'The user with this phone number does not exist',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.smscode.expired': {
    eng: 'The SMS message code has expired. Request another',
    ru: 'Срок действия SMS-кода истек. Запросите повторную отправку',
    pt: 'O código da mensagem SMS expirou. Solicitar outro',
    cn: 'The SMS message code has expired. Request another',
  },
  'PhoneVerificationAndLogin: button to email login': {
    eng: 'Sign in with email',
    ru: 'Войти с помощью Email',
    pt: 'Fazer login com e-mail',
    cn: 'Sign in with email',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.authorization.account.blocked': {
    eng: 'This account is blocked. Please contact our customer support team.',
    ru: 'Данный аккаунт заблокирован. Обратитесь в техническую поддержку.',
    pt: 'Esta conta está bloqueada. Entre em contato com nossa equipe de suporte ao cliente.',
    cn: 'This account is blocked. Please contact our customer support team.',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.authorization.user.disabled': {
    eng: 'This user is blocked. Please contact our customer support team.',
    ru: 'Данный пользователь заблокирован. Обратитесь в техническую поддержку.',
    pt: 'Esta usuário está bloqueada. Entre em contato com nossa equipe de suporte ao cliente.',
    cn: 'This user is blocked. Please contact our customer support team.',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.captcha.missing.header': {
    eng: 'CAPTCHA validation failed',
    ru: 'Ошибка при проверке капчи',
    pt: 'Falha na validação do CAPTCHA',
    cn: 'CAPTCHA validation failed',
  },
  'PhoneVerificationAndLogin:BE-error common.error.internal': {
    eng: 'Internal error: $[1]. Please contact our customer support team with the error code number.',
    ru: 'Внутренняя ошибка $[1]. Обратитесь в техническую поддержку и сообщите код ошибки.',
    pt: 'Erro interno: $[1]. Entre em contato com nossa equipe de suporte ao cliente com o número do código de erro.',
    cn: 'An internal error has occurred. Please contact our customer support team with the following code: $[1]',
  },
  'PhoneVerificationAndLogin:BE-error accountsadmin.smscode.attempts.limit': {
    eng: 'All attempts expired. Please contact our customer support team.',
    ru: 'Все попытки исчерпаны. Обратитесь в техническую поддержку.',
    pt: 'Todas as tentativas expiraram. Entre em contato com nossa equipe de suporte ao cliente.',
  },
};
