import { create } from 'zustand';

import AccountAndUserService from '@just-ai/api/dist/services/AccountsAndUsersService';
import { UserSearchRecord, RoleDto } from '@just-ai/api/dist/generated/Accountsadmin';
import { AppLogger } from '@just-ai/logger';

export type Paging = {
  pageNum: number;
  pageSize: number;
  totalCount: number;
};

export type UserListType = {
  paging?: Paging;
  records: UserSearchRecord[];
};

export interface UsersState {
  users: UserListType;
  admins: UserListType;
  usersOrAdminsLoading: boolean;
  roles: RoleDto[];
  loadUsers({ searchText, page, pageSize }: { searchText?: string; page?: number; pageSize?: number }): void;
  loadAdmins({ searchText, page, pageSize }: { searchText?: string; page?: number; pageSize?: number }): void;
  loadRoles: () => void;
}

const AccountAndUserServiceInstance = new AccountAndUserService();

export const useUsersStore = create<UsersState>(set => ({
  users: {
    records: [],
  },
  admins: {
    records: [],
  },
  roles: [],
  usersOrAdminsLoading: false,
  async loadUsers({ searchText, page, pageSize }: { searchText?: string; page?: number; pageSize?: number }) {
    set({ usersOrAdminsLoading: true });
    const response = await AccountAndUserServiceInstance.findUsersByFilterAsSadmin({
      page,
      searchText: searchText ?? '',
      pageSize,
    }).catch(AppLogger.createNetworkHandler('loadUsers'));
    if (!response) return set({ usersOrAdminsLoading: false });
    set({ users: response, usersOrAdminsLoading: false });
  },

  async loadRoles() {
    const response = await AccountAndUserServiceInstance.getInternalRoles().catch(
      AppLogger.createNetworkHandler('getInternalRoles')
    );
    if (!response) return;
    set({ roles: response });
  },

  async loadAdmins({ searchText, page, pageSize }: { searchText?: string; page?: number; pageSize?: number }) {
    set({ usersOrAdminsLoading: true });
    const response = await AccountAndUserServiceInstance.findInternalUsersByFilterAsSadmin({
      page,
      searchText: searchText ?? '',
      pageSize,
    }).catch(AppLogger.createNetworkHandler('loadUsers'));
    if (!response) return set({ usersOrAdminsLoading: false });
    set({ admins: response, usersOrAdminsLoading: false });
  },
}));
