import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import ProjectGroupsService from '@just-ai/api/dist/services/AccountsadminService';
import { Spinner } from '@just-ai/just-ui';

import localization, { t } from 'localization';
import { axios, isAxiosError } from 'pipes/functions';

import { useAppContext } from 'components/AppContext';
import LoginService from 'service/LoginService';

import { acceptInvitationLocalization } from './localization/acceptInvitation.loc';

const projectGroupService = new ProjectGroupsService(axios);
const loginSevice = new LoginService();

localization.addTranslations(acceptInvitationLocalization);

export const AcceptInvitationByEmail = () => {
  const history = useHistory();
  const { addAlert } = useAppContext();

  const getUserId = useCallback(async () => {
    try {
      const { data } = await loginSevice.checkIsUserAuthorized();
      return data.userData.userId;
    } catch (e) {
      return history.push(`/c/login?need-auth=true&redirectUrl=${window.location}`);
    }
  }, [history]);

  const accept = useCallback(async () => {
    try {
      const userId = await getUserId();
      if (!userId) return;
      const parsedLocationSearch = queryString.parse(history.location.search);
      const token = parsedLocationSearch.token as string;
      await projectGroupService.acceptInvitationByToken(userId, token);
    } catch (error) {
      if (isAxiosError(error)) {
        const errorMessage = error?.response?.data?.error;
        addAlert({
          type: 'error',
          message: t(`AcceptInvitation:BE-error:${errorMessage}`),
          time: Date.now(),
          showed: true,
        });
      }
    } finally {
      history.push('/');
    }
  }, [addAlert, getUserId, history]);

  useEffect(() => {
    accept();
  }, [accept]);

  return <Spinner />;
};
