import React, { Component } from 'react';
import queryString from 'query-string';
import { Spinner } from '@just-ai/just-ui';

import { getDomainData, isDev } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import LoginService from 'service/LoginService';

class ChangeAccount extends Component {
  static contextType = AppContext;

  LoginService = new LoginService();

  componentDidMount() {
    this.changeAccount();
  }

  changeAccount = async () => {
    const {
      // @ts-ignore
      location: { search },
    } = this.props;
    const { appConfig } = this.context;

    const parsedLocationSearch = queryString.parse(search.replace('?', ''));

    const { redirectUrl } = getDomainData(search, appConfig?.domains);
    const accountId =
      parsedLocationSearch?.accountId === 'null' ? undefined : parseInt(parsedLocationSearch?.accountId as string, 10);
    try {
      await this.LoginService.changeAccount(accountId);

      setTimeout(() => {
        this.setState({
          fetching: false,
        });
        window.location.href = isDev() ? '/' : (redirectUrl as string);
      }, 3000);
    } catch (e) {}
  };

  render() {
    return <Spinner size='4x' />;
  }
}

export default ChangeAccount;
