export const commonLocalization = {
  and: {
    eng: 'and',
    ru: 'и',
    pt: 'e',
    cn: 'and',
  },
  Cancel: {
    eng: 'Cancel',
    ru: 'Отмена',
    pt: 'Cancelar',
    cn: '取消',
  },
  Delete: {
    eng: 'Delete',
    ru: 'Удалить',
    pt: 'Excluir',
    cn: '删除',
  },
  'ForgotPassword: submit button text': {
    eng: 'Reset password',
    ru: 'Сбросить пароль',
    pt: 'Redefinir senha',
    cn: 'Reset password',
  },
  'ForgotPassword: form header text': {
    eng: 'Reset password',
    ru: 'Сброс пароля',
    pt: 'Redefinir senha',
    cn: 'Reset password',
  },
  'ForgotPassword: form header success text': {
    eng: 'We have sent you a link to restore access to your account by email. Follow the link to reset your password.',
    ru: 'Мы выслали ссылку для восстановления доступа к аккаунту вам на email. Перейдите по ссылке, чтобы сбросить пароль.',
    pt: 'Enviamos-lhe um link para restaurar o acesso por e-mail à sua conta. Siga o link para redefinir sua senha.',
    cn: 'We have sent you a link to restore access to your account by email. Follow the link to reset your password.',
  },
  'Sidebar: profile': {
    eng: 'Profile',
    ru: 'Профиль',
    pt: 'Perfil',
    cn: 'Profile',
  },
  'Sidebar: accounts': {
    eng: 'Accounts',
    ru: 'Аккаунты',
    pt: 'Accounts',
    cn: 'Accounts',
  },
  'Sidebar: features': {
    eng: 'Feature settings',
    ru: 'Настройки фич',
    pt: 'Feature settings',
    cn: 'Feature settings',
  },
  'Sidebar: users': {
    eng: 'Users',
    ru: 'Пользователи',
    pt: 'Users',
    cn: 'Users',
  },
  'Sidebar: admins': {
    eng: 'Administrators',
    ru: 'Администраторы',
    pt: 'Administrators',
    cn: 'Administrators',
  },
  'OnlyJaicp: form header text': {
    eng: 'Your account is allowed to work only in $[1]',
    ru: 'Для вашей учетной записи разрешена работа только в $[1]',
    pt: 'Sua conta tem permissão para funcionar apenas no $[1]',
    cn: 'Your account is allowed to work only in $[1]',
  },
  'OnlyJaicp: submit button text': {
    eng: 'Go to $[1]',
    ru: 'Перейти в $[1]',
    pt: 'Acessar o $[1]',
    cn: 'Go to $[1]',
  },
  'OnlyJaicp:JAICP': {
    eng: 'JAICP',
    ru: 'JAICP',
    pt: 'JAICP',
  },
  'OnlyJaicp:ToviePlatform': {
    eng: 'Tovie Platform',
    ru: 'Tovie Platform',
    pt: 'Tovie Platform',
  },

  'OauthPage: oauth error text accountsadmin.user.by.email.not.found': {
    eng: 'The user <span>$[1]</span> wasn’t found. You may have entered the wrong username, or your account is not registered.',
    ru: 'Пользователь <span>$[1]</span> не найден. Возможно, вы указали неверный логин или ваша учетная запись не зарегистрирована. ',
    pt: 'O usuário <span>$[1]</span> não foi encontrado. Você pode ter digitado o nome de usuário errado ou sua conta não está registrada.',
    cn: 'The user <span>$[1]</span> wasn’t found. You may have entered the wrong username, or your account is not registered.',
  },
  'OauthPage: oauth error login button accountsadmin.user.by.email.not.found': {
    eng: 'Please try again',
    ru: 'Попробуйте еще раз',
    pt: 'Tente novamente',
    cn: 'Please try again',
  },
  'OauthPage: oauth error text 2 accountsadmin.user.by.email.not.found': {
    eng: ' or contact your account admin',
    ru: ' или обратитесь к вашему администратору',
    pt: ' ou entre em contato com o administrador da sua conta',
    cn: ' or contact your account admin',
  },
  'OauthPage: oauth error text accountsadmin.authorization.account.blocked': {
    eng: 'The account is blocked. ',
    ru: 'Аккаунт заблокирован. ',
    pt: 'A conta está bloqueada. ',
    cn: 'The account is blocked. ',
  },
  'OauthPage: oauth error login button accountsadmin.authorization.account.blocked': {
    eng: 'Log in',
    ru: 'Авторизуйтесь',
    pt: 'Faça login',
    cn: 'Log in',
  },
  'OauthPage: oauth error text 2 accountsadmin.authorization.account.blocked': {
    eng: ' to another account or contact your admin.',
    ru: ' в другом аккаунте или обратитесь к вашему администратору.',
    pt: ' em outra conta ou entre em contato com seu administrador.',
    cn: ' to another account or contact your admin.',
  },
  'OauthPage: oauth error text accountsadmin.authorization.user.disabled': {
    eng: 'The user is blocked. ',
    ru: 'Пользователь заблокирован. ',
    pt: 'A usuário está bloqueada. ',
    cn: 'The user is blocked. ',
  },
  'OauthPage: oauth error login button accountsadmin.authorization.user.disabled': {
    eng: 'Log in',
    ru: 'Авторизуйтесь',
    pt: 'Faça login',
    cn: 'Log in',
  },
  'OauthPage: oauth error text 2 accountsadmin.authorization.user.disabled': {
    eng: ' to another user or contact your admin.',
    ru: ' в другом пользователе или обратитесь к вашему администратору.',
    pt: ' em outra usuário ou entre em contato com seu administrador.',
    cn: ' to another user or contact your admin.',
  },
  'OauthPage: oauth error text accountsadmin.user.by.email.not.found no email': {
    eng: 'This account has no email. ',
    ru: 'У аккаунта отсутствует email. ',
    pt: 'Esta conta não tem e-mail. ',
    cn: 'This account has no email. ',
  },
  'OauthPage: oauth error login button accountsadmin.user.by.email.not.found no email': {
    eng: 'Log in',
    ru: 'Авторизуйтесь',
    pt: 'Faça login',
    cn: 'Log in',
  },
  'OauthPage: oauth error text 2 accountsadmin.user.by.email.not.found no email': {
    eng: ' to another account or contact your admin to add an email',
    ru: ' в другом аккаунте или обратитесь к вашему администратору, чтобы привязать email',
    pt: ' em outra conta ou entre em contato com seu administrador para adicionar um e-mail',
    cn: ' to another account or contact your admin to add an email',
  },
  'Pagination:Founded': {
    eng: 'Found: {size}',
    ru: 'Найдено: {size}',
    pt: 'Encontrado: {size}',
  },
  'Pagination:ToThePage': {
    eng: 'To page',
    ru: 'К странице',
    pt: 'Para a página',
  },
  'Pagination:ShowSize': {
    eng: 'Display',
    ru: 'Показать',
    pt: 'Mostrar',
  },
  'List:NoItems': {
    eng: 'Sorry, but we couldn’t find anything...',
    ru: 'Мы искали, но ничего не нашли...',
    cn: 'Sorry, but we couldn’t find anything...',
  },
};
