import { RegistrationEndpointApi, RegistrationRequestDto } from '../api/cloud/client';
import { axios } from '../pipes/functions';

export default class RegisterService {
  static readonly BASE_PATH = '';
  private RegisterApi: RegistrationEndpointApi;

  constructor() {
    this.RegisterApi = new RegistrationEndpointApi({}, RegisterService.BASE_PATH, axios);
  }

  register = async (registerObject: RegistrationRequestDto, gRecaptchaResponse: any) => {
    try {
      return await this.RegisterApi.register(registerObject, '', {
        headers: { 'g-recaptcha-response': gRecaptchaResponse },
      });
    } catch (error) {
      throw error;
    }
  };

  detectCountryIsoCode = async () => {
    try {
      return await this.RegisterApi.detectCountryIsoCode();
    } catch (error) {
      throw error;
    }
  };
}
