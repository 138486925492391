import { useLayoutEffect } from 'react';
import { history } from '../../App';

const Page404 = () => {
  useLayoutEffect(() => {
    history.push('/');
  }, []);
  return null;
};

export default Page404;
