export const loginLocalization = {
  'Login: form header text': {
    eng: 'Sign in',
    ru: 'Вход в аккаунт',
    pt: 'Fazer login',
    cn: 'Sign in',
  },
  'Login: no account': {
    eng: 'No account? ',
    ru: 'Нет аккаунта? ',
    pt: 'Nenhuma conta? ',
    cn: 'No account? ',
  },
  'Login: register link': {
    eng: 'Create an account',
    ru: 'Зарегистрироваться',
    pt: 'Criar uma conta',
    cn: 'Create an account',
  },
  'Login: ': {
    eng: '',
    ru: '',
    cn: '',
  },
  'Login: forgot password': {
    eng: 'Forgot password?',
    ru: 'Забыли пароль?',
    pt: 'Esqueceu a senha?',
    cn: 'Forgot password?',
  },
  'Login: login with phone': {
    eng: 'Phone number',
    ru: 'Телефона',
    pt: 'Número de telefone',
    cn: 'Phone number',
  },
  'Login: login with google': {
    eng: 'Google',
    ru: 'Google',
    pt: 'Google',
    cn: 'Google',
  },
  'Login: login with github': {
    eng: 'GitHub',
    ru: 'GitHub',
    pt: 'GitHub',
    cn: 'GitHub',
  },
};
