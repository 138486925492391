export const projectGroupSelectLocalization = {
  'ProjectGroupSelect:Header:Title': {
    eng: 'Accounts',
    ru: 'Аккаунты',
  },
  'ProjectGroupSelect:Header:Text:WithGroups': {
    eng: 'You have access to multiple accounts, you will be able to switch between them. Choose the account:',
    ru: 'Вам доступно несколько аккаунтов, вы сможете переключаться между ними. Выберите аккаунт:',
  },
  'ProjectGroupSelect:Header:Text:NoGroups': {
    eng: 'You do not have any accounts yet. You will soon receive an invitation to the account.',
    ru: 'У вас еще нет ни одного аккаунта. Скоро вы получите приглашение в аккаунт.',
  },
  'ProjectGroupSelect:CreateProjectGroup:Text': {
    eng: 'or create a new one',
    ru: 'или создайте новый',
  },
  'ProjectGroupSelect:CreateProjectGroup:Button': {
    eng: 'Create account',
    ru: 'Создать аккаунт',
  },
  'ProjectGroupSelect:InvitedToGroupBadge': {
    eng: 'You were invited',
    ru: 'Вас пригласили',
  },
  'ProjectGroupSelect:DefaultGroup': {
    eng: 'My accounts',
    ru: 'Мои аккаунты',
  },
};
