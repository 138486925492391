import { AuthorizationEndpointApi, AutoLoginDto, ChangeAccountDto, LoginDto } from '../api/cloud/client';
import { axios } from '../pipes/functions';

export default class LoginService {
  static readonly BASE_PATH = '';
  private LoginApi: AuthorizationEndpointApi;

  constructor() {
    this.LoginApi = new AuthorizationEndpointApi({}, LoginService.BASE_PATH, axios);
  }

  login = async (loginObject: LoginDto) => {
    try {
      return await this.LoginApi.login(loginObject);
    } catch (error) {
      throw error;
    }
  };

  checkIsUserAuthorized = async () => {
    try {
      return await this.LoginApi.checkIsUserAuthorized();
    } catch (error) {
      throw error;
    }
  };

  logout = async () => {
    try {
      return await this.LoginApi.logout1();
    } catch (e) {}
  };

  autoLogin = async (token: AutoLoginDto['token']) => {
    try {
      await this.LoginApi.autoLogin({ token });
    } catch (error) {
      throw error;
    }
  };

  changeAccount = async (accountId: ChangeAccountDto['accountId']) => {
    try {
      await this.LoginApi.changeAccount({ accountId });
    } catch (error) {
      throw error;
    }
  };
}
