import { EmailVerificationEndpointApi, SendVerificationEmailDto } from '../api/cloud/client';
import { axios } from '../pipes/functions';

export default class EmailVerificationService {
  static readonly BASE_PATH = '';
  private EmailVerificationApi: EmailVerificationEndpointApi;

  constructor() {
    this.EmailVerificationApi = new EmailVerificationEndpointApi({}, EmailVerificationService.BASE_PATH, axios);
  }

  sendVerificationMail = async (verificationObject: SendVerificationEmailDto) => {
    try {
      await this.EmailVerificationApi.sendVerificationMail(verificationObject);
    } catch (error) {
      throw error;
    }
  };

  getVerificationEmailInfo = async () => {
    try {
      return await this.EmailVerificationApi.getVerificationEmailInfo();
    } catch (error) {
      throw error;
    }
  };
}
