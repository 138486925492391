import { create } from 'zustand';

import { OptionsResponseDto } from '../../../api/cloud/client';

export type ConfigState = {
  options?: OptionsResponseDto;
  setOptions(dto: OptionsResponseDto): void;
};

export const useConfigStore = create<ConfigState>(set => ({
  config: undefined,
  options: undefined,
  setOptions(dto) {
    return set({ options: dto });
  },
}));
