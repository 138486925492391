export const basepageLocalization = {
  'BasePage:BE-error common.validation.constraints.Pattern $.name': {
    eng: 'Full name can only consist of alphanumeric characters',
    ru: 'Имя и фамилия могут содержать только символы алфавита и цифры',
    pt: 'O nome completo só pode consistir em caracteres alfanuméricos',
    cn: 'Full name can only consist of alphanumeric characters',
  },
  'BasePage:BE-error accountsadmin.validation.constraints.UniqueUserEmail $.email': {
    eng: 'This email is already in use',
    ru: 'Этот email уже используется',
    pt: 'Este e-mail já está em uso',
    cn: 'This email is already in use',
  },
  'BasePage:BE-error common.validation.constraints.Email $.email': {
    eng: 'Incorrect email format',
    ru: 'Некорректный формат email',
    pt: 'Formato de e-mail incorreto',
    cn: 'Incorrect email format',
  },
  'BasePage:BE-error accountsadmin.validation.constraints.Email $.email': {
    eng: 'Incorrect email format',
    ru: 'Некорректный формат email',
    pt: 'Formato de e-mail incorreto',
    cn: 'Incorrect email format',
  },
  'BasePage:BE-error accountsadmin.validation.constraints.StrongPassword $.password': {
    eng: 'Weak password. $[1]',
    ru: 'Слабый пароль. $[1]',
    pt: 'Senha fraca. $[1]',
    cn: 'Weak password. $[1]',
  },
  'BasePage:BE-error accountsadmin.validation.constraints.StrongPassword $.newPassword': {
    eng: 'Weak password. $[1]',
    ru: 'Слабый пароль. $[1]',
    pt: 'Senha fraca. $[1]',
    cn: 'Weak password. $[1]',
  },
  'BasePage:BE-error ': {
    eng: '',
    ru: '',
    cn: '',
  },
  'BasePage:BE-error accountsadmin.authorization.bad.credentials': {
    eng: 'Incorrect email or password',
    ru: 'Неверный email или пароль',
    pt: 'Senha ou e-mail incorreto',
    cn: 'Incorrect email or password',
  },
  'BasePage:BE-error password.not.match newPassword': {
    eng: 'Passwords do not match',
    ru: 'Пароли не совпадают',
    pt: 'As senhas não coincidem',
    cn: 'Passwords do not match',
  },
  'BasePage:BE-error common.access.denied': {
    eng: 'Access denied',
    ru: 'Доступ запрещен',
    pt: 'Acesso negado',
    cn: 'Access denied',
  },
  'BasePage:BE-error accountsadmin.password.reset.user.email.not.found': {
    eng: 'The user with this email does not exist',
    ru: 'Нет пользователя с таким email',
    pt: 'O usuário com este e-mail não existe',
    cn: 'The user with this email does not exist',
  },
  'BasePage:BE-error accountsadmin.authorization.account.blocked': {
    eng: 'This account is blocked. Please contact our customer support team.',
    ru: 'Данный аккаунт заблокирован. Обратитесь в техническую поддержку.',
    pt: 'Esta conta está bloqueada. Entre em contato com nossa equipe de suporte ao cliente.',
    cn: 'This account is blocked. Please contact our customer support team.',
  },
  'BasePage:BE-error accountsadmin.captcha.validation.failed': {
    eng: 'CAPTCHA validation failed',
    ru: 'Ошибка при проверке капчи',
    pt: 'Falha na validação do CAPTCHA',
    cn: 'CAPTCHA validation failed',
  },
  'BasePage:BE-error accountsadmin.authorization.auth.attempt.blocked': {
    eng: 'Authentication is blocked. Try again later.',
    ru: 'Аутентификация заблокирована. Попробуйте позже.',
    pt: 'A autenticação está bloqueada. Tente mais tarde.',
    cn: 'Authentication is blocked. Try again later.',
  },
  'BasePage:BE-error common.error.internal': {
    eng: 'An internal error has occurred. Please contact our customer support team with the following code: $[1]',
    ru: 'Произошла внутренняя ошибка. Обратитесь в техническую поддержку со следующим кодом: $[1]',
    pt: 'Ocorreu um erro interno. Entre em contato com nossa equipe de suporte ao cliente com o seguinte código: $[1]',
    cn: 'An internal error has occurred. Please contact our customer support team with the following code: $[1]',
  },
  'BasePage:BE-error accountsadmin.autologin.invalid.token': {
    eng: 'The token has expired',
    ru: 'Токен устарел',
    pt: 'O token expirou',
    cn: 'The token has expired',
  },
  'BasePage:BE-error accountsadmin.validation.constraints.AllowedRedirectDomain': {
    eng: 'A redirect error occurred due to an invalid URL',
    ru: 'Возникла ошибка переадресации из-за некорректного URL',
    pt: 'Ocorreu um erro de redirecionamento devido a um URL inválido',
    cn: 'A redirect error occurred due to an invalid URL',
  },
};
