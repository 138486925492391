import React, { FC, useEffect } from 'react';
import { analyticsLocalization } from '@just-ai/analytic-front/dist/Reporter/localization/analytics.loc.js';
import { Locale } from '@just-ai/just-ui/dist/Common';

import localize from 'localization';
import { appStores } from 'store';

import { useAppContext } from 'components/AppContext';
import Footer from 'components/Footer';
import GlobalAlerts from 'components/GlobalAlerts';
import Header from 'components/Header';
import { isSAdmin } from 'helpers/isAccessFunction';

import styles from './styles.module.scss';

localize.addTranslations(analyticsLocalization);

const Full: FC = ({ children }) => {
  const loadFeatures = appStores.Features(store => store.loadFeatures);
  const loadDefaultFeatures = appStores.Features(store => store.loadDefaultFeatures);

  const { currentUser, alerts, dismissAllAlerts, language } = useAppContext();

  useEffect(() => {
    if (isSAdmin()) {
      loadFeatures();
      loadDefaultFeatures();
    }
  }, [currentUser?.userData.userId, loadDefaultFeatures, loadFeatures]);
  return (
    <div className={styles.Full}>
      <Header />
      <div className={styles.Full__content}>{children}</div>
      <Footer />
      <GlobalAlerts alerts={alerts} dismissAllAlerts={dismissAllAlerts} locale={language as Locale} />
    </div>
  );
};

export default Full;
